import {commonService} from "./common.services";
import {ES_INDEX, API_PATH, HOME_PAGE} from '../constants/index';
import {encode} from "../utilities/CustomFunctions";
import moment from "moment";
export const userService = {
    userLogin,
    socialUserLogin,
    findStories,
    findSections,
    findCategories,
    findStoryDetails,
    findSubscriptionPlans,
    findPageContents,
    findAds,
    saveContactForm,
    findSocialLinks,
    sendOTP,
    verifyOTP,
    addSubscriber,
    renewSubscriber,
    updateProfile,
    checksubscriberexists,
    addBookMarks,
    findBookmarks,
    findBookmarkedStories,
    deleteBookmarks,
    forgotPassword,
    resetPassword,
    placeOrder,
    saveSignupPayment,
    //savePaymentSuccess,
    ohariPaymentInfo,
    existsTransaction,
    logRegister,
    updateLog,
    generateToken,
    paymentTransactionLog,
    transactionsAPILog
};


function userLogin(data){
   // params['index'] = ES_INDEX;
    let params = {
        "collectionid": "397_1614314033",
        "username": data.username,
        "password": btoa(data.password),
        "deviceid": window.deviceid || "",
        "index": ES_INDEX,
        "device": ""
    };
    return commonService.sendHttpRequest('custom/subscribersignin', params, "POST", false);
}
function socialUserLogin(data){
    //params['index'] = ES_INDEX;
    let params = {
        "collectionid": "397_1614314033",
        "fieldid": "394693111",
        "email": data.email,
        "index": ES_INDEX,
        "deviceid": window.deviceid || "",
    }

    return commonService.sendHttpRequest('custom/subscriberexternalsignin', params, "POST", false);
}

function updateProfile(data){
    let params = {
        "index": ES_INDEX,
        "uid": "2",
        "collectionid": "397_1614314033",
        "recordid": data.userId,
        "fields": [
            {
                "external_linked": "no",
                "fieldname": "Name",
                "label": "name",
                "linked": "No",
                "masterid": "1061478411",
                "password": "No",
                "type": "text",
                "value": [{"refIndex": 0, "value": data.name}]
            },
            {
                "external_linked": "no",
                "fieldname": "Mobile",
                "label": "mobile",
                "linked": "No",
                "masterid": "566812281",
                "password": "No",
                "type": "number",
                "value": [{"refIndex": 0, "value": data.mobile}]
            },
            {
                "external_linked": "no",
                "fieldname": "Profession",
                "label": "profession",
                "linked": "No",
                "masterid": "1346678559",
                "password": "No",
                "type": "checkbox",
                "value": [{"refIndex": 0, "value": data.profession}]
            }
        ]
    }
    return commonService.sendHttpRequest( 'collection/fieldupdate', params, "POST", false);
}

function findStories(data = {}){//alert('userSignUp')
    let params ={
        'index': ES_INDEX,
        'cid': '397_244862853552',
        'pstatus': 'Published'
    };
    if(data.search) {
        params['search'] = data.search;
    }
    if(data.section) {
        params['section'] = data.section;
    }
    if((!data.section || data.section === '') && !data.category && !data.pdate && data.allstories !== false) {
        params['allstories'] = true;
    } else {
        params['allstories'] = false;
    }
    if(data.search) {
        params['allstories'] = false;
    }
    if(data.category) {
        params['category'] = data.category;
    }
    if(data.pdate) {
        params['pdate'] = data.pdate;
    }
    if(data.page) {
        params['page'] = data.page;
    }

    //console.log(params)
    return commonService.sendHttpRequest('custom/ohari', params, "GET", false);

    /*return axios.get("stories.json", {
    })*/
}

function findSections(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "filters": [],
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "sort": [{type: "number", order: "asc", id: "479860454"}]
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_1154002604755/collectedinfo', params, "POST", true);
}
function findCategories(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "filters": [],
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_1351578342858/collectedinfo', params, "POST", true);
}
function findSubscriptionPlans(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "filters": [],
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "sort": [{type: "number", order: "asc", id: "1460871813"}]
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_799025542128/collectedinfo', params, "POST", true);
}
function findStoryDetails(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "397_244862853552",
        "metainfo": true,
        "flaginfo": true,
        "_id": param
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/selectedcollection?index=' + ES_INDEX, params, "POST", true);
}
function findPageContents(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "filters": [],
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_545620168314/collectedinfo', params, "POST", true);
}
function findAds(param){
    let params = {};
    let today = new Date();
    let m = (parseInt(today.getMonth()) + 1) < 10 ? '0' + (parseInt(today.getMonth()) + 1) : (parseInt(today.getMonth()) + 1);
    let d = (parseInt(today.getDate())) < 10 ? '0' + (parseInt(today.getDate())) : (parseInt(today.getDate()));
    let day = today.getFullYear() + '-' + m + '-' + d;

    params = {
        "index": ES_INDEX,
        "filters": [],
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "datefilters": [{id: "1252359566", from: day, to: '' , label: ""}],
        "sort":[{type: "text", order: "asc", id: "335490961"}]

    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_1283380933800/collectedinfo', params, "POST", true);
}
function saveContactForm(param) {

    let concatFormData = param.name + " " + param.email + " " + param.subject + " " + param.message;
    let formData = [
        {
            "type":"text",
            "masterid":"132938105",
            "label":"txt-name",
            "value":[{"value": param.name}],
            "external_linked":"false"
        },
        {
            "type":"email",
            "masterid":"229381043",
            "label":"email",
            "value":[{"value": param.email}],
            "external_linked":"false"
        },
        {
            "type":"text",
            "masterid":"1479748208",
            "label":"txt-subject",
            "value":[{"value": param.subject}],
            "external_linked":"false"
        },
        {
            "type":"textarea",
            "masterid":"1561788838",
            "label":"textarea-message",
            "value":[{"value": param.subject}],
            "external_linked":"false"
        }

    ];


    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "2_844919569247",
        reference: "",
        referenceID: "2_844919569247",
        uid: "2",
        index: ES_INDEX
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}
function saveSignupPayment(param, plan, type) {
    let method = localStorage.getItem('_pMtd') || '';
    let concatFormData = param.name + " " + param.email + " " + param.mobile + " " + param.paymentId + " " + plan.text_plan+ " " + param.status+ " " + method + " " + type;
    let formData = [
        {
            "type":"text",
            "masterid":"1061478411",
            "label":"name",
            "value":[{"value":param.name,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"number",
            "masterid":"566812281",
            "label":"mobile",
            "value":[{"value":param.mobile,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"email",
            "masterid":"394693111",
            "label":"email",
            "value":[{"value":param.email,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"text",
            "masterid":"39195794",
            "label":"payment_id",
            "value":[{"value":param.paymentId,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type": "text",
            "masterid": "282663545",
            "label": "order_id",
            "value": [{
                "value": param.orderId,
                "refIndexNo": 0
            }],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "553379741",
            "label": "plan",
            "value": [{
                "value": plan.text_plan,
                "refIndexNo": 0
            }],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "138673911",
            "label": "amount",
            "value": [{
                "value": plan.text_offer_price,
                "refIndexNo": 0
            }],
            "external_linked": "no"
        },
        {
            "type": "switch",
            "masterid": "400751181",
            "label": "status",
            "value": [{
                "text": param.status,
                "weightage": "",
                "correct": "No",
                "value": param.status,
                "refIndexNo": 0
            }],
            "external_linked": "no",
            "optionstatus": "textoption"
        },
        {
            "type": "text",
            "masterid": "1597822499",
            "label": "transaction_type",
            "value": [{
                "value": method,
                "refIndexNo": 0
            }],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "402872871",
            "label": "Subscription Type",
            "value": [{
                "value": type,
                "refIndexNo": 0
            }],
            "external_linked": "no"
        }
    ];


    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "2_1615635998",
        reference: "",
        referenceID: "2_1615635998",
        uid: "2",
        index: ES_INDEX
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}
/*function savePaymentSuccess(param) {
    let concatFormData = param.name + " " + param.email + " " + param.mobile + " " + param.paymentId;
    let formData = [
        {
            "type":"text",
            "masterid":"1061478411",
            "label":"name",
            "value":[{"value":param.name,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"number",
            "masterid":"566812281",
            "label":"mobile",
            "value":[{"value":param.mobile,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"email",
            "masterid":"394693111",
            "label":"email",
            "value":[{"value":param.email,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"text",
            "masterid":"39195794",
            "label":"payment_id",
            "value":[{"value":param.paymentId,"refIndexNo":0}],
            "external_linked":"no"
        }
    ];


    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "2_1619591835",
        reference: "",
        referenceID: "2_1619591835",
        uid: "2",
        index: ES_INDEX
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}*/
function findSocialLinks(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "filters": [],
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_1310560356644/collectedinfo', params, "POST", true);
}
function sendOTP(param){
    let params = {};
    params = {
        "collectionid": "397_1614314033",
        "fieldid": "566812281",
        "mobile": param.mobile,
        "index": ES_INDEX,
        "key": "lsuser12345!",
        "service": "twilio",
        "msg": "is your OTP for Hedge Ohari subscription. The OTP will expire in 180 seconds.",
        "international": true
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/sendbsynotp', params, "POST", true);
}
function verifyOTP(param){
    let params = {};
    params = {
        "collectionid": "397_1614314033",
        "mobile": param.mobile,
        "index": ES_INDEX,
        "otp": param.otp
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/verifybsynotp', params, "POST", true);
}
function addSubscriber(param){
    //console.log(param);
    let params = {};
    let sDate = param.startDate.split('-');
    let eDate = param.endDate.split('-');
    //let mailContentStartDate = sDate[2] + '/' + sDate[1] + '/' + sDate[0];
    //let mailContentEndDate = eDate[2] + '/' + eDate[1] + '/' + eDate[0];
    let mailContentStartDate = moment(new Date(sDate[0], parseInt(sDate[1]) - 1, sDate[2])).format('MMM D, YYYY')
    let mailContentEndDate = moment(new Date(eDate[0], parseInt(eDate[1]) - 1, eDate[2])).format('MMM D, YYYY')
    params = {
        "index": ES_INDEX,
        "data": [
            {
                "cid" : "397_1614314033",
                "rid" : "",
                "uniqueid" :"1235269903",
                "uniquecheck" : "394693111*email",
                "reference" : "entity",
                "referenceid" : "",
                "linkedid" : ["813055810"],
                "content" : [{
                    "subscriber_ id" :"",
                    "salutation" :"",
                    "name": param.name,
                    "mobile":param.mobile,
                    "email":param.email,
                    "dtp_dob":param.dob === '' ? '' : moment(param.dob).format('YYYY-MM-DD'),
                    "start date": param.startDate,
                    "profession":param.profession,
                    "status":"Active",
                    "renewal_date": "",
                    "expire on": param.endDate,
                    "dtp-last-active":"",
                    "login type": param.loginType,
                    "device_id": window.deviceid || "",
                    "Subscription Plan": [
                        {
                            "value": param.plan.text_plan,
                            "recordid": param.plan.id,
                            "collection": "397_799025542128",
                            "fieldid": "1580361562"
                        }
                    ]
                }   ]
            },{
                "cid": "2_1536601039764",
                "rid": "mas_subscript_id",
                "uniquecheck" : "",
                "reference": "collection",
                "referenceid": "",
                "linkedid" : ["801613580","411618930"],
                "content": [{
                    "mas_subscript_id":"",
                    "subscriber_name": param.name,
                    "sub_mobile": param.mobile,
                    "drp-subscription-plan": [
                        {
                            "value": param.plan.text_plan,
                            "recordid": param.plan.id,
                            "collection": "397_799025542128",
                            "fieldid": "1580361562"
                        }
                    ],
                    "dtp-start-date": param.startDate,
                    "dtp-end-date": param.endDate,
                    "dtp-due-date-renewal": param.endDate,
                    "drp-sub-status":"Active"
                }]
            }
        ],
        "mailcontent": [
            {
                "Subscription Plan" : param.plan.text_plan,
                "Start Date" : mailContentStartDate,
                "End Date" : mailContentEndDate,
                "Payment Transaction Number" : param.paymentId,
                "Pre Tax Amount" : param.plan.text_offer_price,
                "GST Amount" : 0,
                "Total Amount" : param.plan.text_offer_price
            }
        ],
        /*"reg_details": [{
            "name": param.name,
            "email": param.email,
            "password": param.password
        }],*/
        "uid": 2
    };
    if(param.loginType === 'internal') {
        params["reg_details"] = [{
            "name": param.name,
            "email": param.email,
            "password": btoa(param.password),
            "user_category": param.user_category
        }];
    }

    return commonService.sendHttpRequest(API_PATH + 'collection/savecollectionrecord', params, "POST", true);
}
function renewSubscriber(param){
    console.log(param);
    let sDate = param.renewalDate.split('-');
    let eDate = param.expireDate.split('-');
    let mailContentStartDate = sDate[2] + '/' + sDate[1] + '/' + sDate[0];
    let mailContentEndDate = eDate[2] + '/' + eDate[1] + '/' + eDate[0];
    let params = {};
    params = {
        "index": ES_INDEX,
        "_id": param.userId,
        "uniqueid": param.name,
        "data": [
            {
                "cid" : "397_1614314033",
                "rid" : "",
                "uniqueid" :"1235269903",
                "uniquecheck" : "394693111*email",
                "reference" : "entity",
                "referenceid" : param.referenceID,
                "linkedid" : ["813055810"],
                "content" : [{
                    "subscriber_ id" :param.subscriberId,
                    "salutation" :"",
                    "name": param.name,
                    "mobile":param.mobile,
                    "email":param.email,
                    "start date": param.startDate,
                    "profession":param.profession,
                    "status":"Active",
                    "renewal_date": param.renewalDate,
                    "expire on": param.expireDate,
                    "dtp-last-active":"",
                    "login type": param.loginType,
                    "Subscription Plan": [
                        {
                            "value": param.plan.text_plan,
                            "recordid": param.plan.id,
                            "collection": "397_799025542128",
                            "fieldid": "1580361562"
                        }
                    ]
                }   ]
            },{
                "cid": "2_1536601039764",
                "rid": "mas_subscript_id",
                "uniquecheck" : "",
                "reference": "collection",
                "referenceid": "",
                "linkedid" : ["801613580","411618930"],
                "content": [{
                    "mas_subscript_id":[
                        {
                            "value": param.subscriberId,
                            "recordid": "161857028554037",
                            "collection": "397_1614314033",
                            "fieldid": "1235269903"
                        }
                    ],
                    "subscriber_name": param.name,
                    "sub_mobile": param.mobile,
                    "drp-subscription-plan": [
                        {
                            "value": param.plan.text_plan,
                            "recordid": param.plan.id,
                            "collection": "397_799025542128",
                            "fieldid": "1580361562"
                        }
                    ],
                    "dtp-start-date": param.renewalDate,
                    "dtp-end-date": param.expireDate,
                    "dtp-due-date-renewal": param.expireDate,
                    "drp-sub-status":"Active"
                }]
            }
        ],
        "mailcontent": [
            {
                "Subscription Plan" : param.plan.text_plan,
                "Start Date" : mailContentStartDate,
                "End Date" : mailContentEndDate,
                "Payment Transaction Number" : param.paymentId,
                "Pre Tax Amount" : param.plan.text_offer_price,
                "GST Amount" : 0,
                "Total Amount" : param.plan.text_offer_price
            }
        ],
        /*"reg_details": [{
            "name": param.name,
            "email": param.email,
            "password": param.password
        }],*/
        "uid": 2
    };


    return commonService.sendHttpRequest(API_PATH + 'custom/upgradesubscriptionplan', params, "POST", true);
}
function checksubscriberexists(param){
    let params = {};
    params = {
        "collectionid": "397_1614314033",
        "fieldid": "394693111",
        "email": param.email,
        "index": ES_INDEX
    };
// /custom/checksubscriberexists?collectionid=397_1614314033&fieldid=394693111&email=deepthy%40logicalsteps.net&index=hedge
    return commonService.sendHttpRequest(API_PATH + 'custom/checksubscriberexists', params, "GET", true);
}
function addBookMarks(param) {

    let concatFormData = param.uniqueid + " " + param.heading + " " + param.attachment + " " + param.userId;
    let formData = [
        {
            "type":"autofill",
            "masterid":"1530278376",
            "label":"story-id",
            "value":
                [
                    {
                        "value":param.uniqueid,
                        "displayvalue":"",
                        "additionaltext":"",
                        "recordid":"161639621731398",
                        "collection":"397_244862853552",
                        "fieldid":"900656776",
                        "refIndexNo":0
                    }
                ],
            "external_linked":"yes"
        },{
            "type":"text",
            "masterid":"1616568546596",
            "label":"txt-title",
            "value":
                [
                    {
                        "value":param.heading,"refIndexNo":0
                    }
                ],
            "external_linked":"no"
        },{
            "type":"attachment",
            "masterid":"1616568546514",
            "label":"story-image",
            "value":
                [
                    {
                        "extension":1,
                        "uniqvalue":param.attachment,
                        "refIndexNo":0,
                        "value":param.attachment
                    }
                ],
            "external_linked":"no"
        },{
            "type":"text",
            "masterid":"1008419412",
            "label":"txt-user-id",
            "value":
                [
                    {
                        "value":param.userId,
                        "refIndexNo":0
                    }
                ],
            "external_linked":"no"
        },{
            "type":"datepicker",
            "masterid":"1458014233",
            "label":"sub-date",
            "value":[{"value":"","refIndexNo":0}],
            "external_linked":"no"
    }
    ];


    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "2_567414301311",
        reference: "",
        referenceID: "2_567414301311",
        uid: "2",
        index: ES_INDEX
    };


    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}
function findBookmarks(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "filters": [{id: "1008419412", value: [param.userId]}],
        "sort": [{type: "date", order: "desc", id: "1458014233"}],

    };

    params['from'] = param.limit || 0;
    params['size'] = param.offset || 100;

    return commonService.sendHttpRequest(API_PATH + 'collection/2_567414301311/collectedinfo', params, "POST", true);
}
function findBookmarkedStories(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "filters": [{id: "900656776", value: param.bookmarkIds}],
        "allfields": true
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/397_244862853552/collectedinfo', params, "POST", true);
}
function deleteBookmarks(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "2_567414301311",
        "records": [param.recordId],
        "uid": "2"
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/deleterecords', params, "POST", true);
}
function forgotPassword(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "email": param.email,
        "url": HOME_PAGE + "reset-password",
        "uid": "2"
    };

    return commonService.sendHttpRequest(API_PATH + 'custom/subscriberresetpassword', params, "POST", true);
}
function resetPassword(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "email": param.email,
        "resetid": param.resetid,
        "password": btoa(param.password),
        "uid": "2"
    };

    return commonService.sendHttpRequest(API_PATH + 'custom/subscriberupdatepassword', params, "POST", true);
}
function placeOrder(param){
    let params = {};
    params = {
        "amount": encode(param.amount),
        "currency": encode(param.currency),
        "receipt": encode(param.receipt),
        "payment_capture": encode(param.payment_capture)
    }

    return commonService.sendHttpRequest(API_PATH + "ohari/generateohariorderid", params, "POST", true);

}
function ohariPaymentInfo(param){
    let params = {
        "_id": encode(param.paymentId)
    }
    return commonService.sendHttpRequest(API_PATH + "ohari/oharipaymentinfo", params, "POST", true);
}
/*function existsTransaction(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "filters": [{id: "39195794", value: [param.paymentId]}, {id: "282663545", value: [param.orderId]}]
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/2_1615635998/collectedinfo', params, "POST", true);
}*/
function existsTransaction(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "2_1615635998",
        "payment_fieldid": "39195794",
        "payment_id": encode(param.paymentId),
        "ord_fieldid": "282663545",
        "order_id": encode(param.orderId)
    };

    return commonService.sendHttpRequest(API_PATH + 'custom/CheckSubscriberTransaction', params, "POST", true);
}
function logRegister(param, type) {
    //let plan = JSON.parse(localStorage.getItem('plan'));
    //(plan.refer === 'renew' || plan.refer === 'upgrade') ? 'Renewal' : 'New';
    let today = new Date();
    let m = (parseInt(today.getMonth()) + 1) < 10 ? '0' + (parseInt(today.getMonth()) + 1) : (parseInt(today.getMonth()) + 1);
    let d = (parseInt(today.getDate())) < 10 ? '0' + (parseInt(today.getDate())) : (parseInt(today.getDate()));
    let day = today.getFullYear() + '-' + m + '-' + d;
    let concatFormData = param.name + " " + param.email + " " + param.mobile + " " + param.dob + " " + param.profession + " " + param.selectedPlan['text_plan'] + " " + param.orderId + " " + param.loginType + " " + param.amount + ' ' + type;
    let formData = [{
        "type": "text",
        "masterid": "911192917",
        "label": "name",
        "value": [{
            "value": param.name,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "email",
        "masterid": "1388521552",
        "label": "email",
        "value": [{
            "value": param.email,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "number",
        "masterid": "875123854",
        "label": "phone_number",
        "value": [{
            "value": param.mobile,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "datepicker",
        "masterid": "783934790",
        "label": "dob",
        "value": [{
            "value": param.dob,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "1490492731",
        "label": "profession",
        "value": [{
            "value": param.profession,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "254670427",
        "label": "password",
        "value": [{
            "value": btoa(param.password),
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "68171287",
        "label": "selectedplan",
        "value": [{
            "value": param.selectedPlan['text_plan'],
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "386116033",
        "label": "orderId",
        "value": [{
            "value": param.orderId,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "datepicker",
        "masterid": "679509685",
        "label": "createdDate",
        "value": [{
            "value": day,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "870549563",
        "label": "loginType",
        "value": [{
            "value": param.loginType,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "865074153",
        "label": "paymentId",
        "value": [{
            "value": "Nil",
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "number",
        "masterid": "976925421",
        "label": "amount",
        "value": [{
            "value": param.amount,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "toggle",
        "masterid": "1426680198",
        "label": "notified",
        "value": [{
            "value": 'No',
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }, {
        "type": "text",
        "masterid": "169525397",
        "label": "subscription_type",
        "value": [{
            "value": type,
            "refIndexNo": 0
        }],
        "external_linked": "no"
    }];
//console.log(type);return;

    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "2_1393845022629",
        reference: "",
        referenceID: "2_1393845022629",
        uid: "2",
        index: ES_INDEX
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}
function paymentTransactionLog(param) {
    let today = new Date();
    let m = (parseInt(today.getMonth()) + 1) < 10 ? '0' + (parseInt(today.getMonth()) + 1) : (parseInt(today.getMonth()) + 1);
    let d = (parseInt(today.getDate())) < 10 ? '0' + (parseInt(today.getDate())) : (parseInt(today.getDate()));
    let day = today.getFullYear() + '-' + m + '-' + d;
    let concatFormData = param.orderId + " " + param.paymentId + " " + day + " " + param.status
    let formData = [
        {
            "type":"text",
            "masterid":"399247681",
            "label":"order_id",
            "value":[{"value":param.orderId,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"text",
            "masterid":"1008650636",
            "label":"pay_id",
            "value":[{"value":param.paymentId,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"datepicker",
            "masterid":"248499764",
            "label":"tran_log",
            "value":[{"value":day,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"dropdown",
            "masterid":"716006654",
            "label":"status",
            "value":[{"value":param.status,"refIndexNo":0}],
            "external_linked":"no"
        }
    ];


    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "397_766940151369",
        reference: "",
        referenceID: "397_766940151369",
        uid: "2",
        index: ES_INDEX
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}
function updateLog(data){
    let params = {
        "index": ES_INDEX,
        "uid": "2",
        "collectionid": "2_1393845022629",
        "recordid": data.logId,
        "fields": [
            {
                "type": "text",
                "fieldname": "paymentId",
                "masterid": "865074153",
                "label": "paymentId",
                "value": [{
                    "value": data.paymentId,
                    "refIndexNo": 0
                }],
                "external_linked": "no"
            },
            {
                "type": "text",
                "fieldname": "Status",
                "masterid": "1118508446",
                "label": "txt_status",
                "value": [{
                    "value": data.status,
                    "refIndexNo": 0
                }],
                "external_linked": "no"
            }
        ]
    }
    return commonService.sendHttpRequest( 'collection/fieldupdate', params, "POST", false);
}
function generateToken(param){
    let params = {};
    params = {
        "index": ES_INDEX,
        "email": "admin@bsynapse.com",
        "userid": "2"
    };

    return commonService.sendHttpRequest(API_PATH + 'custom/generatetoken', params, "POST", true);
}
function transactionsAPILog(param) {
    let today = new Date();
    let m = (parseInt(today.getMonth()) + 1) < 10 ? '0' + (parseInt(today.getMonth()) + 1) : (parseInt(today.getMonth()) + 1);
    let d = (parseInt(today.getDate())) < 10 ? '0' + (parseInt(today.getDate())) : (parseInt(today.getDate()));
    let day = today.getFullYear() + '-' + m + '-' + d;
    let concatFormData = param.name + " " + param.orderId + " " + param.action + " " + day;
    let formData = [
        {
            "type":"text",
            "masterid":"679493457",
            "label":"name",
            "value":[{"value":param.name,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"text",
            "masterid":"1433615418",
            "label":"order_id",
            "value":[{"value":param.orderId,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"text",
            "masterid":"67457734",
            "label":"api_action",
            "value":[{"value":param.action,"refIndexNo":0}],
            "external_linked":"no"
        },
        {
            "type":"datepicker",
            "masterid":"880077850",
            "label":"date",
            "value":[{"value":day,"refIndexNo":0}],
            "external_linked":"no",
            "datechange": true
        },
        {
            "type":"text",
            "masterid":"1558902923",
            "label":"more_info",
            "value":[{"value":param.info || '',"refIndexNo":0}],
            "external_linked":"no"
        }
    ];


    let params = {
        child: "",
        formconcat: concatFormData,
        formdata:  JSON.stringify(formData),
        formtype: "",
        mode: "save",
        parent: "2_789574909596",
        reference: "",
        referenceID: "2_789574909596",
        uid: "2",
        index: ES_INDEX
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/createdata', params, "POST", true);

}
